import React from 'react';
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from '../components/seo';
import BackgroundImage from 'gatsby-background-image';
import ValuesList from '../components/values-list';



const People = ( { data } ) => {

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {

                const url = node.data.target.fields.file["en-US"].url
                const alt = node.data.target.fields.title["en-US"]

                return <img style={{ width: "100%" }} alt={alt} src={url}/>
            }
        }
    }



    return ( 
        <Layout>
            <SEO 
            pageType="website"
            linkTitle={data.people.linkTitle}
            pageDescription={data.people.linkDescription.linkDescription}
            pageURL={`${data.webAddress.siteMetadata.url}people`}
            pageTitle={data.people.pageTitle}
            linkImage={`https:${data.people.linkImage.file.url}`}
            />
            <BackgroundImage fluid={data.people.bannerImage.fluid}>
            <TopBanner>
                <PageTitle>{data.people.bannerTitle}</PageTitle>
            </TopBanner>
            </BackgroundImage>
            <LowerSection>
                <Header>Chairman</Header>
                <Chairman>{data.people.chairman}</Chairman>
                <Break>
                    <div className="line"></div>
                </Break>
                <BioBody>
                    <Header style={{paddingTop: "0"}}>Executive Committee</Header>
                    <Bio>{documentToReactComponents(data.people.boardMembers.json, options)}</Bio>

                </BioBody>

            </LowerSection>
           
        </Layout>
    );
}
 
export default People;

export const pageQuery = graphql`
  query {
    people: contentfulPeople(title: {eq: "People"}) {
        pageTitle
        linkTitle
        linkImage {
          file {
            url
          }
        }
        linkDescription {
          linkDescription
        }
        bannerImage {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid
          }
        }
        bannerTitle
        chairman
        boardMembers {
          json
        }
    }
    webAddress: site {
        siteMetadata {
          url
        }
    }
  }
`;

const TopBanner = styled.div`
  background-color: rgba(0, 66, 37, 0.4);
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: 68px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  @media (max-width: 450px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
`

const PageTitle = styled.h1`
  color: #FFFFFF;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  padding: 10px;
  display: inline;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif !important;
  @media (max-width: 450px) {
    font-size: 9vw;
  }
  
`

const LowerSection = styled.div`
  background-color: #FFFFFF;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative
`

const BioBody = styled.div`
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const FeaturedImage = styled(Img)`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: 60px;
  @media (max-width: 450px) {
        margin-bottom: 20px;
    }
`

const Header = styled.h1`
  color: #004225;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  padding: 10px;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif !important;
  text-align: center !important;
  @media (max-width: 450px) {
    font-size: 9vw;
  }
`

const Chairman = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: black;
  text-align: center;
  font-family: 'Cormorant Garamond', serif !important;
  margin: 10px 0 30px 0;
  @media (max-width: 450px) {
    font-size: 4.3vw;
    }
`

const Bio = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  font-family: 'Cormorant Garamond', serif !important;
  margin-top: 20px;
  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
}
`

const Break = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 30px;
  .line{
    max-width: 800px;
    height: 3px;
    margin: 20px auto;
    background-color: #C4C4C4;
    border-radius: 3px;
    
  }
  
`
